* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body {
    overflow-y: scroll;
}

.navMask {
    height: 100px;
}











.box1 {
    margin-top: 20px;
    display: flex;

}

.wallet {
    display: flex;
    width: 104px;
    padding: 5px 23px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid var(--Light-Light-1, #F6F6F6);
    position: relative;
}


.plus {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    fill: #000;
    position: absolute;
    transform: translate(180%, -90%);
}

.wallet>button {
    border: none;
    background-color: transparent;
    cursor: pointer;

}

.userData {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    align-self: center;
}


.user_data {
    display: flex;
    flex-direction: row;
    gap: 0px;
    gap: 20px;
}


.user_data>p {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}



.sec1 {
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    gap: 20px;
    height: fit-content;
}

.balance {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0px;

}

.balance>div {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin: 0px;
    padding: 0px;
}

.balance>div>img {
    height: 30px;
    width: 30px;
}

.balance>div>p {
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;
}

.balance>p {
    color: var(--Light-Light-3, #A4A4A4);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.6px;
}

.league {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
}

.levels {
    display: flex;
    width: 146px;
    height: fit-content;
    padding: 5px 10px;
    align-items: center;
    gap: 2px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.10);
    /* border: 1px solid white; */
}

.leagueLogo {}

.leagueInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}

.leagueInfo>div:nth-child(1) {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.leagueInfo>div:nth-child(1)>p:nth-child(1) {
    color: var(--Light-Light-3, #A4A4A4);
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}

.leagueInfo>div:nth-child(1)>p:nth-child(2) {
    color: var(--Light-Light-3, #A4A4A4);
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}

.leagueInfo>div:nth-child(1)>p:nth-child(2)>span {
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}

.leagueInfo>div:nth-child(2) {
    height: 9px;
    align-self: stretch;
    border-radius: 42px;
    background: rgba(255, 255, 255, 0.10);
}

.crest {
    display: flex;
    /* height: 32px; */
    padding: 14px 10px;
    align-items: center;
    gap: 2px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.10);
}



































.sec1_ {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 0px;

}


.intro {

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 15px 5px;
    margin-top: 0px;
    flex-direction: column;
    gap: 5px;
}



.intro_t1 {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.56px;
}

.intro_t2 {
    color: var(--Light-Light-2, #CDCDCD);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
}

.bonus_types {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.bonus {
    width: 90%;


    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
    min-height: 100px;
    border-radius: 12px;
    background: #33353A;
    padding: 20px 15px;

}


.bonus_data {
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.coin {
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.bonus>button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}


.bonus:last-child {
    padding-top: 10px;
    padding-bottom: 10px;
}

.invite_button {
    display: flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    border-radius: 10px;
    background: var(--Primary-Primary-5, #4E5ADD);

    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
}


.invite_link {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.invite_link_title {
    width: 100%;
    padding: 20px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;

}

.invite_button {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.invite_button>button {
    cursor: pointer;
}

.invite_button>button:nth-child(1) {
    width: 100px;

    height: 36px;
    padding: 10px;

    width: 80%;
    border-radius: 5px;
    background: var(--Primary-Primary-5, #4E5ADD);
    border: none;
    color: #FFF;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.7px;

}

.sec1 .invite-link .invite-button>button:nth-child(2) {
    display: flex;
    width: 69px;
    height: 36px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid var(--Primary-Primary-5, #4E5ADD);
    background-color: transparent;
}

.friendsReferred {
    display: flex;
    width: 90%;
    max-width: 392px;
    height: fit-content;
    min-height: 79px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: var(--Dark-Dark-3, #1E1E1E);
    align-self: center;
    gap: 10px;
}

.friendsReferred>div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.friendsReferred>div>p {
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.7px;
}