.wheelContainer{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.spinWheelContainer{
    width: fit-content;
    height: fit-content;
}
.motionImage{
    display: flex;
    justify-content: center;
    align-items: center;
}










.timerContainer {
    margin-top: 20px;
    background: #2c2c2c;
    border-radius: 10px;
    padding: 12px;
    width: 100%;
    max-width: 400px;
}

.timerHeader {
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.timerValue {
    color: #f4a261;
}

.progressBar {
    height: 8px;
    background: #3a3a3a;
    border-radius: 5px;
    margin-top: 6px;
    overflow: hidden;
}

.progressFill {
    height: 100%;
    background: linear-gradient(90deg, #7b61ff, #b054ff);
    transition: width 1s linear;
}
