
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.box1{
    margin-top: 20px;
    display: flex;

}

.wallet {
    display: flex;
    width: 104px;
    padding: 5px 23px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid var(--Light-Light-1, #F6F6F6);
    position: relative;
}


.plus {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    fill: #000;
    position: absolute;
    transform: translate(180%, -90%);
}

.wallet > button {
    border: none;
    background-color: transparent;
    cursor: pointer;

}

.userData {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    align-self: center;
}


.user_data {
    display: flex;
    flex-direction: row;
    gap: 0px;
    gap: 20px;
}


.user_data>p {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}



.sec1 {
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    gap: 20px;
}

.balance {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0px;

}.balance>div{
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin: 0px;
    padding:0px;
}.balance>div>img{
    height: 30px;
    width: 30px;
}.balance>div>p{
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;   
}.balance>p{
    color: var(--Light-Light-3, #A4A4A4);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.6px;
}
.league {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
}.levels {
    display: flex;
    width: 146px;
    height: fit-content;
    padding: 5px 10px;
    align-items: center;
    gap: 2px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.10);
    /* border: 1px solid white; */
}
.leagueLogo{

}
.leagueInfo{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}.leagueInfo>div:nth-child(1){
    display: flex;
    width: 100%;
    justify-content: space-between;
}.leagueInfo>div:nth-child(1)>p:nth-child(1){
    color: var(--Light-Light-3, #A4A4A4);
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px; 
}.leagueInfo>div:nth-child(1)>p:nth-child(2){
    color: var(--Light-Light-3, #A4A4A4);
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}.leagueInfo>div:nth-child(1)>p:nth-child(2)>span{
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}
.leagueInfo>div:nth-child(2){
    height: 9px;
    align-self: stretch;
    border-radius: 42px;
    background: rgba(255, 255, 255, 0.10);
}
.crest{
    display: flex;
    /* height: 32px; */
    padding: 14px 10px;
    align-items: center;
    gap: 2px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.10);
}


.box2AreaMask{
    display: flex;
    position: relative;
    flex-direction: column;
    height: fit-content;
}

.box2_mask {
    position: absolute;
    top: 30px;
    border: 1px solid green;
    width: 100%;
    box-sizing: border-box;
    height: 118px;
    flex-shrink: 0;
    border-radius: 0px 0px 30px 30px;
    background: #4E5ADD;
    filter: blur(30px);
    /* background-color:transparent; */
}
.box2 {
    position: relative;
    height: 500px;
    margin-top: 40px;
    overflow: scroll;
    background: black;
    z-index: 2;
    border-radius: 30px 30px 0px 0px;
    border-top: 2px solid var(--Primary-Primary-5, #4E5ADD);
    background: #1C1F24;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.spinContainer{
    align-self: center;

    display: flex;
    justify-self: center;
    align-items: center;

    flex-direction: column;
    margin-left: 5px;
}


.navMask {
    height: 100px;
}
