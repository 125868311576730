*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.box1{
    margin-top: 20px;
    display: flex;
    margin-bottom:50px ;

}

.wallet {
    display: flex;
    width: 104px;
    padding: 5px 23px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid var(--Light-Light-1, #F6F6F6);
    position: relative;
}


.plus {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    fill: #000;
    position: absolute;
    transform: translate(180%, -90%);
}

.wallet > button {
    border: none;
    background-color: transparent;
    cursor: pointer;

}

.userData {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    align-self: center;
}


.user_data {
    display: flex;
    flex-direction: row;
    gap: 0px;
    gap: 20px;
}


.user_data>p {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}



.sec1 {
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    gap: 20px;
}

.balance {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0px;

}.balance>div{
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin: 0px;
    padding:0px;
}.balance>div>img{
    height: 30px;
    width: 30px;
}.balance>div>p{
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;   
}.balance>p{
    color: var(--Light-Light-3, #A4A4A4);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.6px;
}
.league {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
}.levels {
    display: flex;
    width: 146px;
    height: fit-content;
    padding: 5px 10px;
    align-items: center;
    gap: 2px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.10);
    /* border: 1px solid white; */
}
.leagueLogo{

}
.leagueInfo{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}.leagueInfo>div:nth-child(1){
    display: flex;
    width: 100%;
    justify-content: space-between;
}.leagueInfo>div:nth-child(1)>p:nth-child(1){
    color: var(--Light-Light-3, #A4A4A4);
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px; 
}.leagueInfo>div:nth-child(1)>p:nth-child(2){
    color: var(--Light-Light-3, #A4A4A4);
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}.leagueInfo>div:nth-child(1)>p:nth-child(2)>span{
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}
.leagueInfo>div:nth-child(2){
    height: 9px;
    align-self: stretch;
    border-radius: 42px;
    background: rgba(255, 255, 255, 0.10);
}
.crest{
    display: flex;
    /* height: 32px; */
    padding: 14px 10px;
    align-items: center;
    gap: 2px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.10);
}






.payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 15px;
}



.paymentSectionHeader {
    color: #FFF;
    text-align: center;
    font-family: Ubuntu, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.64px;
}


.selectTransactionDataType {
    display: flex;
    flex-wrap: nowrap;
    width: 80%;
    gap: 15px;

    display: flex;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 5px;
    background: var(--Dark-Dark-2, #272A2F);

    justify-content: space-around;
    align-items: center;
    /* padding: 16px 25px; */
    align-self: center;

}


.selectTransactionDataType>button {
    border: none;
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.7px;
    background-color: transparent;
    align-self: center;
    cursor: pointer;
    width: 100%;
}

.transactionSelected {

    background: var(--Dark-Dark-3, #1E1E1E);
    border: 1px solid white;
}






.paymentForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 25px;
    margin-top: 50px;
}

.payAmount {

    width: 80%;
    display: flex;
    max-width: 362px;
    padding: 20px;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background: var(--Dark-Dark-3, #1E1E1E);
    border: none;
    outline: none;

    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
}

.paymentHeader {
    color: #FFF;
    font-family: Inter, Ubuntu, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.paymentFormSubmitButton {
    display: flex;
    width: 362px;
    padding: 15px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: var(--Primary-Primary-5, #4E5ADD);
    border: none;

    color: #FFF;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.8px;
    cursor: pointer;

    width: 80%;
}

.paymentHistory {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;
    align-self: center;

    justify-content: center;
    align-items: center;
    padding: 5px 20px;

    border-radius: 10px;
    background: var(--Dark-Dark-2, #272A2F);
}

.historyRow {
    display: flex;
    justify-content: space-between;
    width: 100%;

    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.28px;

}

.depositionType {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 80%;
    align-self: center;

    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;

    border-radius: 10px;
    background: #33353A;

}


.totalDeposit {
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
    width: fit-content;
    justify-content: flex-start;
    align-items: flex-start;
    justify-self: flex-start;
    align-self: flex-start;
    gap: 10px;
}

.totalDeposit>p {
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;

    padding: 0px;
    margin: 0px;
}

.totalDeposit>div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;

    padding: 0px;
    margin: 0px;
}

.totalDeposit>div>p {
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.7px;

    padding: 0px;
    margin: 0px;
}





.navMask {
    height: 100px;
}














.k {}