*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
main {
    display: flex;
    flex-direction: column;
}




.box1{
    margin-top: 20px;
    display: flex;

}

.wallet {
    display: flex;
    width: 104px;
    padding: 5px 23px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid var(--Light-Light-1, #F6F6F6);
    position: relative;
}


.plus {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    fill: #000;
    position: absolute;
    transform: translate(180%, -90%);
}

.wallet > button {
    border: none;
    background-color: transparent;
    cursor: pointer;

}

.userData {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    align-self: center;
}


.user_data {
    display: flex;
    flex-direction: row;
    gap: 0px;
    gap: 20px;
}


.user_data>p {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}



.sec1 {
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    gap: 20px;
}

.balance {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0px;

}.balance>div{
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    height: fit-content;
    margin: 0px;
    padding:0px;
}.balance>div>img{
    height: 30px;
    width: 30px;
}.balance>div>p{
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1px;   
}.balance>p{
    color: var(--Light-Light-3, #A4A4A4);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.6px;
}
.league {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    align-items: center;
}.levels {
    display: flex;
    width: 146px;
    height: fit-content;
    padding: 5px 10px;
    align-items: center;
    gap: 2px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.10);
    /* border: 1px solid white; */
}
.leagueLogo{

}
.leagueInfo{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
}.leagueInfo>div:nth-child(1){
    display: flex;
    width: 100%;
    justify-content: space-between;
}.leagueInfo>div:nth-child(1)>p:nth-child(1){
    color: var(--Light-Light-3, #A4A4A4);
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px; 
}.leagueInfo>div:nth-child(1)>p:nth-child(2){
    color: var(--Light-Light-3, #A4A4A4);
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}.leagueInfo>div:nth-child(1)>p:nth-child(2)>span{
    color: var(--Light-Light-1, #F6F6F6);
    text-align: center;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.5px;
}
.leagueInfo>div:nth-child(2){
    height: 9px;
    align-self: stretch;
    border-radius: 42px;
    background: rgba(255, 255, 255, 0.10);
}
.crest{
    display: flex;
    /* height: 32px; */
    padding: 14px 10px;
    align-items: center;
    gap: 2px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.10);
}




.gameSec1 {
    width: 98%;
    align-self: center;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;

    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.7px;
    border-radius: 5px;
    background: var(--Dark-Dark-2, #272A2F);
    padding: 10px;

}

.gameSec1>button {
    width: fit-content;
    border: none;
    background-color: transparent;
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.7px;
    padding: 10px 25px;
    border-radius: 5px;
    cursor: pointer;
}

.gameSec1button:hover,
.gameSec1 .selected {
    border-radius: 5px;
    background: var(--Dark-Dark-3, #1E1E1E);
}

.gameSec2 {
    display: flex;
    width: 100%;
    /* height: fit-content; */
    padding: 2%;
    justify-content: space-around;
    margin-top: 20px;
    /* border: 1px solid white; */
    gap: 10px;
    flex-wrap: wrap;
    max-height: 80%;
    overflow: scroll;
    align-items: stretch;
}


.game {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    flex-direction: column;
    gap: 8px;
    width: fit-content;
    padding: 10px;
    justify-content: space-around;
    align-items: center;
    margin-top: 5px;
    border-radius: 5px;
    background: var(--Dark-Dark-2, #272A2F);
    align-self: stretch;


    
}


.gameSec2> a{
    text-decoration: none;

}
.game>img {
    width: 80px;
    height: auto;
    border-radius: 5px;
    
    justify-content: center;
    align-items: center;
}

.game>p {
    color: var(--Light-Light-1, #F6F6F6);
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.6px;
    text-align: center;
    max-width: 80px;
}